<template>
  <div class="stock-page">
    <KTCodePreview v-bind:title="'Danh sách tồn kho'">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="exportToExcelStream">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Xuất Excel</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập tên, mã, mã vạch sản phẩm"
              v-model="searchProduct"
              append-icon="search"
              single-line
              hide-details
              class="input-style"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div>
              <vue-autosuggest
                v-model="selectedCateName"
                :suggestions="filteredCateOptions"
                @selected="onSelectedCate"
                :limit="200"
                @input="onInputCateChange"
                :input-props="{
                  class: 'autosuggest__input',
                  placeholder: 'Chọn danh mục',
                }"
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  {{ suggestion.item.suggestionName }}
                </div></vue-autosuggest
              >
            </div>
          </b-col>
          <b-col>
            <multiselect
              v-model="value"
              :options="optionStores"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelect($event)"
              @remove="onRemove($event)"
              :show-labels="false"
              :showNoResults="true"
              :showPointer="false"
              :custom-label="customLabel"
              placeholder="Chọn cửa hàng"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ value.length }} cửa hàng đã chọn</span
                ></template
              >
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="checkbox-item"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col>
            <vue-autosuggest
              v-model="selectedCate2Name"
              :suggestions="filteredInternalCateOptions"
              @selected="onSelectedCate2"
              :limit="200"
              @input="onInputCate2Change"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn danh mục nội bộ',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.suggestionName }}
              </div></vue-autosuggest
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <vue-autosuggest
              v-model="selectedBrandName"
              :suggestions="filteredBrandOptions"
              @selected="onSelectedBrand"
              :limit="200"
              @input="onInputBrandChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Chọn thương hiệu',
              }"
            >
              <div slot-scope="{ suggestion }">
                {{ suggestion.item.name }}
              </div></vue-autosuggest
            >
          </b-col>
          <b-col class="mb-4">
            <div style="display: flex !important">
              <b-form-select
                size="sm"
                v-model="selectedProductType"
                :options="listProductType"
                value-field="id"
                text-field="name"
                class="col-md-8 select-style"
              ></b-form-select>
              <span class="ml-1 mr-1"></span>
              <b-checkbox v-model="haveStock" class="mt-2">Có tồn</b-checkbox>
            </div>
          </b-col>
          <b-col>
            <b-checkbox v-model="byParent" class="mt-2"
              >Tồn theo SP cha</b-checkbox
            >
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
          <b-col class="d-flex d-inline justify-content-end">
            <span class="mr-2">
              <i class="fas fa-circle fa-sm text-dark"></i>
              Tồn có thể bán
            </span>
            <span class="mr-2" v-if="settingTable.quantity.includes(2)">
              <i class="fas fa-circle fa-sm text-primary"></i>
              Đang chuyển kho
            </span>
            <span class="mr-2" v-if="settingTable.quantity.includes(3)">
              <i class="fas fa-circle fa-sm text-warning"></i>
              Đang giữ hàng
            </span>
            <span class="mr-2" v-if="settingTable.quantity.includes(4)">
              <i class="fas fa-circle fa-sm text-danger"></i>
              Đang vận chuyển
            </span>
            <b-dropdown
              no-caret
              size="sm"
              variant="secondary"
              v-b-tooltip.hover
              title="Hiển thị"
              right
            >
              <template v-slot:button-content>
                <i
                  class="flaticon2-settings"
                  style="font-size: 1rem; padding-right: 0px"
                >
                </i>
              </template>

              <b-dropdown-header id="dropdown-header-label">
                <strong>Hiển thị:</strong>
              </b-dropdown-header>

              <div class="dropdown-item bg-white text-body">
                <b-form-checkbox-group
                  v-model="settingTable.quantity"
                  :options="[
                    {
                      id: 1,
                      name: 'Tồn có thể bán',
                      disabled: true,
                    },
                    {
                      id: 2,
                      name: 'Đang chuyển kho',
                    },
                    {
                      id: 3,
                      name: 'Đang giữ hàng',
                    },
                    {
                      id: 4,
                      name: 'Đang vận chuyển',
                    },
                  ]"
                  stacked
                  value-field="id"
                  text-field="name"
                ></b-form-checkbox-group>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>

        <div id="scroll-table">
          <b-table
            :items="listData"
            :fields="fields"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
            </template>

            <template v-slot:cell(productName)="row">
              <span
                v-if="row.item.barCode"
                v-text="row.item.barCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
                class="d-block"
              ></span>
              <b
                v-if="row.item.productCode"
                class="d-block"
                style="font-size: 12px; font-weight: 500; margin-bottom: 0"
              >
                ( {{ row.item.productCode }} )
              </b>
              <b
                v-text="row.item.productName"
                class="d-block"
                style="white-space: normal"
              ></b>
            </template>
            <template v-slot:cell(sellingPrice)="row">
              <div style="text-align: end">
                <span v-text="convertPrice(row.item.sellingPrice)"></span>
              </div>
            </template>
            <template v-slot:cell(totalQuantityInStock)="row">
              <div style="text-align: end">
                <b
                  v-text="row.item.totalQuantityInStock"
                  class="mr-1"
                  v-b-tooltip.hover.bottom
                  title="Tổng tồn có thể bán"
                ></b>
                <b
                  class="text-primary mr-1 font-size-setting"
                  v-if="
                    settingTable.quantity.includes(2) &&
                    row.item.totalQuantityTranfer
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang chuyển kho"
                >
                  {{ '+' + row.item.totalQuantityTranfer }}
                </b>
                <b
                  class="text-warning font-size-setting"
                  v-if="
                    settingTable.quantity.includes(3) &&
                    row.item.totalQuantityHolding
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang giữ hàng"
                >
                  {{ '+' + row.item.totalQuantityHolding }}
                </b>
                <b
                  class="text-danger font-size-setting"
                  v-if="
                    settingTable.quantity.includes(4) &&
                    row.item.totalQuantityDelivery
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang vận chuyển"
                >
                  {{ '+' + row.item.totalQuantityDelivery }}
                </b>
              </div>
            </template>
            <template #cell()="data">
              <div v-html="data.value"></div>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scope>
.d-flex {
  display: -webkit-box !important;
}
</style>
<style>
.labelInput {
  font-weight: 600;
}
.productCode:hover {
  text-decoration: underline;
}

.inputText {
  width: 22%;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link {
  font-weight: 500;
  font-size: 1rem;
}

.customPagination /deep/ .page-item .page-link:hover i {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.selectedProClass {
  width: 4%;
  text-align: center;
}
.productNameClass {
  width: 20%;
}
.productNameNotPriceClass {
  width: 20%;
}
.providerClass {
  width: 12%;
}
.storeClass {
  width: 12%;
  align-items: center;
}
.importPriceProClass {
  width: 10%;
}
.salePriceProClass {
  width: 10%;
}
.statusProClass {
  width: 15%;
}
.dropdown-header {
  color: #3699ff !important;
}

.imeiCode:hover {
  text-decoration: underline;
}
</style>
<style lang="scss">
.stock-page {
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  .input-style {
    border: 1px solid #ced4da;
  }

  .checkbox-item {
    position: absolute;
    right: 1vw;
  }

  .checkbox-label {
    display: block;
  }

  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }

  .autosuggest__results {
    position: absolute;
    width: 100%;
    max-height: 200px;
    z-index: 999;
    border: 1px solid #e0e0e0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.35em + 1.1rem + 2px);
    line-height: 1.35;
    padding: 0.55rem 0.75rem;
    font-weight: 400;
    font-size: 0.925rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.28rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }

  table {
    margin: 0;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    th {
      position: relative;
      z-index: 40;
    }

    td,
    th {
      vertical-align: middle;
      padding: 0.75rem;
    }

    th:first-child {
      position: sticky !important;
      left: 0;
      z-index: 41;
      background-color: white;
    }
    th:nth-child(2) {
      position: sticky !important;
      left: 250px;
      z-index: 41;
      background-color: white;
    }

    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: white;
    }

    td:nth-child(2) {
      position: sticky;
      left: 250px;
      z-index: 1;
      background-color: white;
    }

    .font-size-setting {
      font-size: 0.86rem;
    }
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { currencyMask, removeAccents } from './../../../utils/common';
// import axios from 'axios';
import decounce from 'debounce';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import { BASE_URL } from './../../../utils/constants';
import fileDownload from '../../../utils/file-download';

export default {
  data() {
    return {
      selectedProduct: '',
      originalPrice: '',
      sellingPrice: '',
      productName: '',
      productId: '',
      hoverPrice: false,
      parentProductId: '',
      childProductId: '',
      selectedBrand: null,
      listBrand: [],
      currentSort: 'createdAt',
      currentSortDir: 'DESC',
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            width: '250px',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '160px',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tổng tồn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ],
      search: '',
      listData: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listProduct: [],
      showEditPriceModal: false,
      mask: currencyMask,
      listStore: [],
      selectedStore: null,
      searchStock: '',
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      selectedProductType: null,
      value: [],
      optionStores: [],
      haveStock: true,
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      brandOptions: [
        {
          data: [],
        },
      ],
      filteredBrandOptions: [],
      selectedBrandId: null,
      selectedBrandName: '',
      internalCateOptions: [
        {
          data: [],
        },
      ],
      filteredInternalCateOptions: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      byParent: false,
      settingTable: {
        quantity: [1, 2, 3, 4],
      },
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Multiselect,
  },
  computed: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tồn kho', route: '/productStock' },
      { title: 'Danh sách tồn kho' },
    ]);
    this.fetchStoreForOption();
    this.fetchStore();
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    let storeId = this.$route.query.storeId;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    if (storeId !== undefined) {
      this.selectedStore = storeId;
    }
    this.fetchCategory();
    this.fetchBrand();
    this.checkViewOriginalPrice();
    this.fetchInternalCategory();
  },
  methods: {
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      let listId = [];
      this.optionStores.forEach((element) => {
        if (element.checked) {
          listId.push(element.code);
        }
      });
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedBrandName.trim() === '') {
        this.selectedBrandId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      const params = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct,
        searchStore: listId,
        productType: this.selectedProductType,
        checkStock: this.haveStock,
        cateId: this.selectedCateId,
        brandId: this.selectedBrandId,
        internalCateId: this.selectedCate2Id,
        byParent: this.byParent,
      };

      ApiService.query('productStock/getAll', {
        params,
      }).then(({ data }) => {
        this.listData = data.data.data;
        console.log(this.listData);
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
        this.onLoading = false;
      });
    },
    sortingChanged(ctx) {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      this.currentSort = ctx.sortBy;
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchBrand() {
      this.listBrand = [];
      let param = {
        page: 1,
        pageSize: 1000,
        name: '',
      };
      let paramQuery = {
        params: {
          param,
        },
      };
      ApiService.setHeader();
      ApiService.query('brands', paramQuery).then((response) => {
        response.data.brands.forEach((item) => {
          let brand = {
            id: item.id,
            name: item.name,
          };
          this.brandOptions[0].data.push(brand);
        });
        this.filteredBrandOptions = [{ data: this.brandOptions[0].data }];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
      this.fetchData();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    getParamFilters() {
      const storeIds = this.getValuesMultiSelect(this.optionStores, 'code');

      return {
        storeIds: storeIds,
        searchProduct: this.searchProduct ? this.searchProduct.trim() : null,
        cateId: this.selectedCateId || null,
        internalCateId: this.selectedCate2Id || null,
        brandId: this.selectedBrandId || null,
        productType: this.selectedProductType || null,
        haveStock: this.haveStock || null,
      };
    },
    exportToExcelStream: function () {
      const baseUrl = BASE_URL;
      const params = this.getParamFilters();

      axios
        .get(`${baseUrl}productStock/export-excel`, {
          params: params,
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        });
    },
    debounceInput: decounce(function () {
      this.fetchData();
    }, 2000),
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice'
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'productNameNotPriceClass';
            this.fields[index].thClass = 'productNameNotPriceClass';
            break;
          }
        }
      }
    },
    fetchStoreForOption: async function () {
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionStores.push(option);
        });
      });
    },
    fetchStore: async function () {
      this.fields = [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '250px',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '160px',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tổng tồn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ];
      let listId = [];
      this.optionStores.forEach((element) => {
        if (element.checked) {
          listId.push(element.code);
        }
      });
      let param = {
        listId: listId,
      };
      let paramQuery = {
        params: param,
      };

      ApiService.query(`/stores/getStores`, paramQuery).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
          let field = {
            key: element.id + '',
            label: element.shortName,
            sortable: false,
            thStyle: {
              textAlign: 'center',
              color: 'rgb(24, 28, 50)',
              width: '100px',
            },
            tdClass: 'text-right',
            formatter: (value) => {
              let strBase = `<b class='text-dark' title='Tồn có thể bán'>${value.quantityInStock}</b>`;

              if (value.transferInStock) {
                const haveShow = this.settingTable.quantity.includes(2);
                strBase += `<b class='prop-transfer text-primary font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang chuyển kho'>
                     +${value.transferInStock}
                      </b>`;
              }

              if (value.holdingInStock) {
                const haveShow = this.settingTable.quantity.includes(3);
                strBase += `<b class='prop-holding text-warning font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang giữ hàng'>
                      +${value.holdingInStock}
                      </b>`;
              }

              if (value.deliveryInStock) {
                const haveShow = this.settingTable.quantity.includes(4);
                strBase += `<b class='prop-holding text-danger font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang vận chuyển'>
                      +${value.deliveryInStock}
                      </b>`;
              }

              return strBase;
            },
          };
          // if (this.fields.length < 12) {
          this.fields.push(field);
          // }
        });
      });
    },
    onSelect(option) {
      let index = this.optionStores.findIndex(
        (item) => item.name == option.name
      );
      this.optionStores[index].checked = true;
    },

    onRemove(option) {
      let index = this.optionStores.findIndex(
        (item) => item.name == option.name
      );
      this.optionStores[index].checked = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-stock',
      });
      this.fetchStore();
      this.fetchData();
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [{ data: this.cateOptions[0].data }];
      });
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedBrand(option) {
      this.selectedBrandId = option.item.id;
      this.selectedBrandName = option.item.name;
    },
    onInputBrandChange(text) {
      this.selectedBrandName = text;

      const filteredData = this.brandOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredBrandOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.internalCateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredInternalCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchInternalCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.internalCateOptions[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.internalCateOptions[0].data.push(item);
          });
          this.filteredInternalCateOptions = [
            { data: this.internalCateOptions[0].data },
          ];
        }
      );
    },
  },
};
</script>
